//テーブル
//テキストアラインはユーティリティクラスを使ってください。

.c-tbl {
  //共通設定
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th {
    background-color: rgba($gray,.3);
  }
  th,td {
    border: 1px solid $bdrColor;
    padding: 15px 20px;
    text-align: left;
  }


  //テーブルタイプ
  //ブロック
  &--block {
    tbody {
      tr {
        @include mq-max(xs) {
          &:not(:last-child) {
            td{
              border-bottom: none;
            }
          }
        }
        th {
          @include mq-max(xs) {
            border-bottom: none !important;
            text-align: left;
          }
        }
        // td {}
        th,td {
          @include mq-max(xs) {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  //Fixed
  &--fixed {
    table-layout: fixed;
    tbody {
      tr {
        // th {}
        // td {}
        th,td {
          @include mq-max(sm) {
            padding: 15px 10px;
          }
        }
      }
    }
    .c-tbl {
      &__item {
        //長さ指定のクラスをここに作成
        &--w-50per {
          width: 50%;
        }
        &--w-25per {
          width: 25%;
        }
        &--w-200px {
          width: 200px;
        }
      }
    }
  }

  //スクロール型
  &__scroll {
    @include mq-max(xs) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid $bdrColor;
      border-radius: 5px;
      &::before {
        content: "←スライドで表の詳細を確認できます→";
        color: #666;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center;
      }
      table {
        min-width: 700px; //ここは状況に応じて値を変更してください
        th, td {
          display: table-cell;
        }
        th {
          white-space: nowrap;
        }
      }
    }
  }
}