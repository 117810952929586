.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 2;
  &:last-child {
    margin-bottom: 0;
  }

  //丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &::before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: 0;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: -0.1em;
        }
      }
    }
  }

  //カウント
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      &::before{
        counter-increment: count;
        content:counter(count);
        position: absolute;
        left: -1.1em;
        color: $keyColor;
      }
    }
    &--brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: -1.5em;
          color: $keyColor;
        }
      }
    }
  }

  //注釈
  &--comment {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &::before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
    //番号付き注釈
    &-num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &::before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: -1.5em;
          @media all and (-ms-high-contrast: none) {
            top: 0;
          }
        }
      }
    }
  }
}